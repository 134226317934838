import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { sagRoutes } from "./staffs_and_groups";
import {
  userRoutes,
  userAktRoutes,
  userPassTestingRoutes,
  headRoutes
} from "./user";
import { constructorRoutes } from "./constructor";
import { skillRoutes } from "./skill";
import { teamRoutes } from "./team";
import { aktRoutes, aktOtherRoutes } from "./akt";
import { priorities } from "./priorities";
import { subscriptionRoutes } from "./subscription";
import { positionRoutes } from "./positions";
import { kpiRoutes, kpiSettingRoutes, kpiReportRoutes } from "./kpi";
import { supervisorDashboardChildRoutes } from "./supervisor_dashboard";
import { checkRoutes } from "@/router/check_testings";
import { TasksRoute } from "@/router/task";
import { settingsRoutes } from "@/router/settings";

Vue.use(VueRouter);

const employeesRoutes: RouteConfig[] = [
  ...teamRoutes,
  ...sagRoutes,
  ...positionRoutes,
  TasksRoute,
  {
    path: "/user_signs",
    name: "user_signs",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "user_signs",
      name: "Признаки сотрудников"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/Signs.vue")
  }
];

const organizationRoutes: RouteConfig[] = [
  {
    path: "/structures",
    name: "structures",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "structures",
      name: "Орг. структура"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Structures.vue")
  },
  {
    path: "/inventories",
    name: "inventories",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "inventories_disable",
      name: "Группы оборудования"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Inventories.vue")
  },
  ...skillRoutes,
  {
    path: "/skill_signs",
    name: "skill_signs",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "skill_signs",
      name: "Признаки навыков"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SkillSigns.vue")
  },
  ...constructorRoutes,
  ...priorities,
  {
    path: "/local_block",
    name: "local_block",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "local_block",
      name: "Группы навыков"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LocalBlocks.vue")
  }
];

const trainingRoutes: RouteConfig[] = [
  {
    path: "/training_planning",
    name: "training_planning",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "training_planning",
      name: "Планирование обучения"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TrainingPlanning.vue")
  },
  {
    path: "/training_applications",
    name: "training_applications",
    props: true,
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "training_applications",
      name: "Заявки на обучение"
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/TrainingApplications.vue"
      )
  },
  {
    path: "/appoint_responsible",
    name: "appoint_responsible",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "appoint_responsible",
      name: "Ответственные"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AppointResponsible.vue")
  }
];

const testRoutes: RouteConfig[] = [
  {
    path: "/testings",
    name: "testings",
    props: true,
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "testings",
      name: "Тестирование"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/testings/Testings.vue")
  },
  ...checkRoutes
];

const analyticsRoutes: RouteConfig[] = [
  {
    path: "/analytics",
    name: "analytics",
    props: true,
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "analytics",
      name: "Аналитика"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/analytics/Analytics.vue")
  },
  {
    path: "/supervisor_dashboard",
    name: "supervisor_dashboard",
    props: true,
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "supervisor_dashboard",
      name: "Дашборд руководителя"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dashboards.vue"),
    children: supervisorDashboardChildRoutes,
    redirect: { name: "skill_categories" }
  }
];

/** Отображается в навигационном меню */
export const navRoutes: RouteConfig[] = [
  {
    path: "/profile",
    name: "profile",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "profile",
      child_menu: true,
      name: "Профиль"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Profile.vue"),
    children: userRoutes,
    redirect: { name: "me_dashboard" }
  },
  {
    path: "/employees",
    name: "employees",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "employees",
      child_menu: true,
      name: "Сотрудники"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Employee.vue"),
    children: employeesRoutes,
    redirect: { name: "staffs_and_groups" }
  },
  {
    path: "/organization",
    name: "organization",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "organization",
      child_menu: true,
      name: "Организация"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Organization.vue"),
    children: organizationRoutes,
    redirect: { name: "structures" }
  },
  {
    path: "/training",
    name: "training",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "training",
      child_menu: true,
      name: "Обязательное обучение"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Organization.vue"),
    children: trainingRoutes,
    redirect: { name: "training_application" }
  },
  {
    path: "/test",
    name: "test",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "test",
      child_menu: true,
      name: "Тесты"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Test.vue"),
    children: testRoutes,
    redirect: { name: "testing" }
  },
  {
    path: "/analytical",
    name: "analytical",
    meta: {
      checkJwt: true,
      checkPermission: false,
      namePermission: "analytical",
      child_menu: true,
      name: "Аналитика"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Organization.vue"),
    children: analyticsRoutes,
    redirect: { name: "analytics" }
  },
  ...aktRoutes,
  ...kpiRoutes,
  ...settingsRoutes
];

export const authRoutes: RouteConfig[] = [
  {
    path: "/dev_auth",
    name: "dev_auth",
    meta: {
      checkJwt: false,
      name: "Авторизация"
    },
    component: () => import(/* webpackChunkName: "about" */ "../views/Auth.vue")
  },
  {
    path: "/auth/callback_digital_library",
    name: "auth_callback",
    meta: {
      checkJwt: false,
      name: "OpenID Connect авторизация"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AuthCallback.vue")
  },
  {
    path: "/auth",
    name: "auth",
    meta: {
      checkJwt: false,
      name: "auth"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AuthMiddle.vue")
  }
];

const technicalWorkRoute: RouteConfig = {
  path: "/technical_work",
  name: "technical_work",
  meta: {
    name: "Ведутся технические работы",
    checkPermission: false,
    checkJwt: true,
    namePermission: "technical_work"
  },
  component: () => import("../views/errors/TechnicalWork.vue")
};

const downloadAppRoutes: RouteConfig[] = [
  {
    path: "/download_app",
    name: "download_app",
    meta: {
      checkJwt: false,
      name: "Приложение"
    },
    component: () => import("../views/download_app/DownloadApp.vue")
  },
  {
    path: "/ios",
    name: "ios",
    meta: {
      checkJwt: false,
      name: "iOS"
    },
    component: () => import("../views/download_app/IOSInstallation.vue")
  },
  {
    path: "/android",
    name: "android",
    meta: {
      name: "Android",
      checkJwt: false
    },
    component: () => import("../views/download_app/AndroidInstallation.vue")
  }
];

const serviceUnavailableRoute: RouteConfig = {
  path: "/service_unavailable",
  name: "service_unavailable",
  meta: {
    checkJwt: false,
    name: "service_unavailable"
  },
  component: () => import("../views/errors/ServiceUnavailable.vue")
};

const rootRoute: RouteConfig = {
  path: "/",
  name: "root",
  redirect: { name: "me_dashboard" },
  meta: {
    checkJwt: true
  }
};

const unknownRoute: RouteConfig = {
  path: "*",
  name: "unknown",
  meta: {
    checkJwt: false,
    name: "unknown"
  },
  component: () => import("../views/errors/NotFound.vue")
};

const routes: RouteConfig[] = [
  ...userAktRoutes,
  ...userPassTestingRoutes,
  ...headRoutes,
  ...authRoutes,
  ...navRoutes,
  ...subscriptionRoutes,
  ...aktOtherRoutes,
  ...kpiSettingRoutes,
  ...kpiReportRoutes,
  ...downloadAppRoutes,
  technicalWorkRoute,
  rootRoute,
  serviceUnavailableRoute,
  unknownRoute
];

const router = new VueRouter({
  scrollBehavior() {
    const el = document.querySelector(".content-right");
    if (el) {
      el.scrollTop = 0;
    }
  },
  mode: "history",
  routes
});

export default router;
