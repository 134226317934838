import { RouteConfig } from "vue-router";

interface IChild {
  title: string | string[] | undefined;
  badge?: string | undefined;
  id: string | undefined;
  to?: { name: string };
}

export interface IRoute extends IChild {
  children?: IChild[];
}

export function parseNav(
  routes: RouteConfig[],
  current_user: IJWTUser | null,
  ignore_profile = false
) {
  return routes.reduce((acc, curr) => {
    if (ignore_profile && curr.name === "profile") {
      return acc;
    }

    if (curr.meta!.checkPermission) {
      if (
        !current_user?.permissions.find(
          p => p.name == curr.meta!.namePermission
        )?.read &&
        !current_user?.is_developer
      ) {
        return acc;
      }
    }

    const route: IRoute = {
      title: curr.meta!.name,
      badge: curr.meta!.badge,
      id: curr.name
    };

    if (curr.meta!.child_menu && curr.children?.length) {
      route.children = parseNav(curr.children, current_user);
    } else {
      route.to = { name: curr.name! };
    }

    return acc.concat(route);
  }, [] as IRoute[]);
}
