import Vue from "vue";
import Component from "vue-class-component";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./registerServiceWorker";

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

import VModal from "vue-js-modal";
Vue.use(VModal);

import vApi from "./api/api.plugin";
Vue.use(vApi);

import linkify from "vue-linkify";
Vue.directive("linkified", linkify);

import VueInfinityScroll from "vue-infinite-scroll";
Vue.use(VueInfinityScroll);

import FloatingVue, { Dropdown } from "floating-vue";
import "floating-vue/dist/style.css";
// Сделан такой хак, т.к. если юзать options при Vue.use(...) ломается v-tooltip из vuetify
Object.assign(FloatingVue.options.themes, {
  "dropdown-menu": {
    $extend: "dropdown",
    $resetCss: true
  }
});
Vue.component("VDropdown", Dropdown);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import Notifications from "vue-notification";
Vue.use(Notifications);

import lodash from "lodash";
import VueLodash from "vue-lodash";
Vue.use(VueLodash, { lodash: lodash });

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import pdf from "vue-pdf";
Vue.component("v-pdf", pdf);

import TextareaAutosize from "vue-textarea-autosize";
Vue.use(TextareaAutosize);

import VueJSDatepicker from "vuejs-datepicker";
Vue.component("v-datepicker", VueJSDatepicker);

import Vue2Timepicker from "vue2-timepicker";
Vue.component("v-timepicker", Vue2Timepicker);

import { InlineSvgPlugin } from "vue-inline-svg";
Vue.use(InlineSvgPlugin);

import ActionCableVue from "actioncable-vue";
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectionUrl: process.env.VUE_APP_WEBSOCKET,
  connectImmediately: true
});

import {
  normalizedSubscriptionStatus,
  normalizedSubscriptionInspectorStatus,
  normalizedSubscriptionLevel
} from "@/filters/program";

Vue.filter(
  "normalizedSubscriptionInspectorStatus",
  normalizedSubscriptionInspectorStatus
);
Vue.filter("normalizedSubscriptionStatus", normalizedSubscriptionStatus);
Vue.filter("normalizedSubscriptionLevel", normalizedSubscriptionLevel);

import {
  normalizedTestingAssignmentStatus,
  normalizedTestingStatus
} from "@/filters/testing";
Vue.filter("normalizedTestingStatus", normalizedTestingStatus);
Vue.filter(
  "normalizedTestingAssignmentStatus",
  normalizedTestingAssignmentStatus
);

import {
  normalizedApplicationStatus,
  normalizedApplicationStatusColor,
  normalizedApplicationType
} from "./filters/application";
Vue.filter("normalizedApplicationType", normalizedApplicationType);
Vue.filter("normalizedApplicationStatus", normalizedApplicationStatus);
Vue.filter(
  "normalizedApplicationStatusColor",
  normalizedApplicationStatusColor
);

import {
  normalizedTaskStatus,
  normalizedTaskStatusColor,
  normalizedTaskStatusIcon,
  normalizedTaskCategory
} from "@/filters/task";
Vue.filter("normalizedTaskStatus", normalizedTaskStatus);
Vue.filter("normalizedTaskCategory", normalizedTaskCategory);
Vue.filter("normalizedTaskStatusColor", normalizedTaskStatusColor);
Vue.filter("normalizedTaskStatusIcon", normalizedTaskStatusIcon);

import {
  normalizedCategory,
  normalizedEducationTypes,
  normalizedSkillDocumentTypes
} from "./filters/skill";
Vue.filter("normalizedSkillDocumentTypes", normalizedSkillDocumentTypes);
Vue.filter("normalizedEducationTypes", normalizedEducationTypes);
Vue.filter("normalizedCategory", normalizedCategory);

import { normalizedKpiSubLevelType } from "./filters/kpi";
Vue.filter("normalizedKpiSubLevelType", normalizedKpiSubLevelType);

import { month_text } from "./filters/month_text";
Vue.filter("month_text", month_text);
import { rows_count_success_skills } from "./filters/normalized_count";
Vue.filter("rows_count_success_skills", rows_count_success_skills);
import { rows_count_failure_skills } from "./filters/normalized_count";
Vue.filter("rows_count_failure_skills", rows_count_failure_skills);

import {
  normalizeType,
  normalizedSkillsCount,
  normalizedUserCount,
  normalizedPauseCount,
  normalizedQuestionResultCount,
  normalizedSeconds,
  normalizedTimer,
  toDate,
  toDateTime,
  toBlogDateTime,
  formatName,
  getFileName,
  getAcronim,
  monthFromIndex,
  normalizedAcceptFiles,
  monthFromNumber,
  normalizedMinutesCount,
  normalizedFileName
} from "./filters";
Vue.filter("normalizeType", normalizeType);
Vue.filter("normalizedSkillsCount", normalizedSkillsCount);
Vue.filter("normalizedUserCount", normalizedUserCount);
Vue.filter("normalizedMinutesCount", normalizedMinutesCount);
Vue.filter("normalizedPauseCount", normalizedPauseCount);
Vue.filter("normalizedQuestionResultCount", normalizedQuestionResultCount);
Vue.filter("normalizedFileName", normalizedFileName);
Vue.filter("normalizedSeconds", normalizedSeconds);
Vue.filter("normalizedTimer", normalizedTimer);
Vue.filter("toDate", toDate);
Vue.filter("toDateTime", toDateTime);
Vue.filter("toBlogDateTime", toBlogDateTime);
Vue.filter("formatName", formatName);
Vue.filter("getFileName", getFileName);
Vue.filter("formatName", formatName);
Vue.filter("getAcronim", getAcronim);
Vue.filter("normalizedAcceptFiles", normalizedAcceptFiles);
Vue.filter("monthFromIndex", monthFromIndex);
Vue.filter("monthFromNumber", monthFromNumber);

import {
  normalizedAktQuestionType,
  normalizedAktStatus,
  normalizedAktType,
  normalizedAktText,
  normalizedApprove,
  normalizedAssignmentUserWorplaceStatus,
  normalizedAssignmentUserWorplaceResultStatus
} from "./filters/akt";
Vue.filter("normalizedAktQuestionType", normalizedAktQuestionType);
Vue.filter("normalizedAktStatus", normalizedAktStatus);
Vue.filter("normalizedAktType", normalizedAktType);
Vue.filter("normalizedAktText", normalizedAktText);
Vue.filter("normalizedApprove", normalizedApprove);
Vue.filter(
  "normalizedAssignmentUserWorplaceStatus",
  normalizedAssignmentUserWorplaceStatus
);
Vue.filter(
  "normalizedAssignmentUserWorplaceResultStatus",
  normalizedAssignmentUserWorplaceResultStatus
);

import {
  truncate,
  humanizedDate,
  normalizedMessage
} from "./filters/dashboard";
Vue.filter("truncate", truncate);
Vue.filter("humanizedDate", humanizedDate);
Vue.filter("normalizedMessage", normalizedMessage);

Vue.config.productionTip = false;

import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);

/**
 * Register the router hooks with their names
 * https://github.com/vuejs/vue-class-component#adding-custom-hooks
 */
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate" // for vue-router 2.2+
]);

import vuetify from "@/plugins/vuetify";

const app = new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");

import notificationRoutes from "@/api/routes/notifications";
import { NavigationGuardNext } from "vue-router";

const defineCurrentUser = async (app: Vue, next: NavigationGuardNext<Vue>) => {
  if (!app.$api.authorizedUser()) {
    await app.$api.getUserData().then(({ data: res }: { data: IJWTUser }) => {
      app.$store.dispatch("updateCurrentUser", {
        authorized: true,
        currentUser: res
      });

      if (res.is_lock) {
        next({
          name: "technical_work"
        });
      }
    });
  }
};

app.$router.beforeResolve(async (to, _from, next) => {
  if (to.matched.some(r => r.meta.checkJwt)) {
    if (await app.$api.authorizeCheck()) {
      await defineCurrentUser(app, next);

      if (to.matched.some(r => r.meta.checkPermission)) {
        if (
          !to.matched.some(
            r =>
              app.$api
                .authorizedUser()
                ?.permissions.find(p => p.name == r.meta?.namePermission)?.read
          ) &&
          !app.$api.authorizedUser()?.is_developer
        ) {
          next({ name: "unknown" });
        }
      }

      app.$api
        .get(notificationRoutes.notifications_count)
        .then(({ data: res }: { data: number }) => {
          app.$store.commit("countNotification", res);
        })
        .finally(() => {
          app.$store.dispatch("updateCurrentUser", {
            authorized: true,
            currentUser: app.$api.authorizedUser()
          });

          next();
        });
    } else {
      next({
        name: "auth"
      });
    }
  } else {
    next();
  }
});
