import { RouteConfig } from "vue-router";

const settingsChildRoutes: RouteConfig[] = [
  {
    path: "/banner_settings",
    name: "banner_settings",
    meta: {
      name: "Настройки баннера",
      checkPermission: true,
      checkJwt: true,
      only_developer: true
    },
    component: () => import("../views/settings/Banners.vue")
  },
  {
    path: "/imported_files",
    name: "imported_files",
    meta: {
      name: "Отчеты по импорту",
      checkPermission: true,
      only_developer: true,
      checkJwt: true
    },
    component: () => import("../views/settings/Import.vue")
  },
  {
    path: "/mobile_app_versions",
    name: "mobile_app_versions",
    meta: {
      name: "Загрузка приложения",
      checkPermission: true,
      only_developer: true,
      checkJwt: true
    },
    component: () => import("../views/settings/MobileAppVersions.vue")
  },
  {
    path: "/mobile_logs",
    name: "mobile_logs",
    meta: {
      name: "Логи мобильного приложения",
      checkPermission: true,
      only_developer: true,
      checkJwt: true
    },
    component: () => import("../views/settings/Logs.vue")
  }
];

/** Отображается в навигационном меню */
export const settingsRoutes: RouteConfig[] = [
  {
    path: "/settings.ts",
    name: "settings",
    meta: {
      checkJwt: true,
      checkPermission: true,
      namePermission: "developer",
      only_developer: true,
      child_menu: true,
      name: "Настройки администратора"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
    children: settingsChildRoutes,
    redirect: { name: "mobile_logs" }
  }
];
